
import HeaderOther from '../reutilizable/HeaderOther';
import {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CrearTarget from './CrearTarget';
import { Link } from 'react-router-dom';
import API_BASE_URL from '../apiConfig';
import Header from '../reutilizable/Header';

function Targets() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { codigo,id} = useParams();
    const [tesoros, setTesoros] = useState([]);

    useEffect(() => {
      // Realizar solicitud GET para obtener el JSON de tesoros
      fetch(`${API_BASE_URL}/api/proyectos/listarTargets.php?codigoProyecto=${codigo}`)
        .then(response => response.json())
        .then(data => {
          // Actualizar el estado con los datos obtenidos
          setTesoros(data.tesoros);
          console.log(data.tesoros);
        })
        .catch(error => {
          console.error('Error al obtener los tesoros:', error);
        });
    }, []);
    
    const borrarTarget = (targetId) => {
      const confirmacion = window.confirm('¿Estás seguro de que quieres borrar este target?');
    
      if (confirmacion) {
        fetch(`${API_BASE_URL}/api/proyectos/borrarTarget.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id_target: targetId }),
        })
          .then(response => response.json())
          .then(data => {
            console.log(data); // Puedes mostrar un mensaje o hacer cualquier otra acción con la respuesta de la API
    
            // Actualiza la página después de borrar el proyecto
            window.location.reload();
          })
          .catch(error => {
            console.error('Error al borrar el target:', error);
          });
      }
    };

    const openModal = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };

    return (

    <main className='main-cuerpo'> 
    <Header/>
    <div className='cuerpo-principal'>

    <HeaderOther />

    <div className='cuerpo-datos'>

    <div className='box-botones-arriba'>
    <button className="button-verde" onClick={() => openModal()}>
        <span className="button__text">Nuevo Target</span>
        <span className="button__icon">
          <i className="bi bi-plus-circle"></i>
        </span>
        </button>
      <CrearTarget isOpen={isModalOpen} closeModal={closeModal} idProyecto={id}/>
    </div>
    <div className='box-flex'>

    <div className='tabla-container'>
    <table className='table-normal'>
        <thead className='titulos-barra-tabla'>
          <tr>
            <th>Tipo</th>
            <th>Nombre</th>
            <th>Editar</th>
            <th>Borrar</th>
          </tr>
        </thead>
        <tbody className='tabla-contexto'>
          {tesoros.map(tesoro => (
            <tr key={tesoro.id}>
              <td>
              {tesoro.tipo === 'sonido' && <i className="boton-lista bi-music-note-beamed"></i>}
              {tesoro.tipo === 'pdf' && <i className="boton-lista bi-filetype-pdf"></i>}
              {tesoro.tipo === 'youtube' && <i className="boton-lista bi-film"></i>}
              {tesoro.tipo === 'imagen' && <i className="boton-lista bi-card-image"></i>}
              {tesoro.tipo === 'normal' && <i className="boton-lista bi-fonts"></i>}
              {tesoro.tipo === 'encuesta' && <i className="boton-lista bi-list-ol"></i>}
              </td>
              <td>{tesoro.name}</td>
              <td>
              <button type="button"className="boton-lista-verde"><i className="bi bi-pencil"></i></button>
              </td>
              <td>
              <button onClick={() => borrarTarget(tesoro.id)} type="button" className="boton-lista-rojo"><i className="bi-trash3-fill"></i></button>
              </td>
              
        
            </tr>
          ))}
        </tbody>
      </table>
  </div>

    </div>
    </div>
    </div>
    </main>
      );
}

export default Targets;