import React, { useState, useEffect } from 'react';
import '../estilos/Alertas.css';

const Alertas = ({ titulo, mensaje, tipo, icon, estado }) => {
  const [mostrar, setMostrar] = useState(false);

  useEffect(() => {
    setMostrar(estado);
  }, [estado]);

  // Determina la clase de estilo según el tipo de alerta
  const tipoClase = tipo === 'error' ? 'alerta-error' : 'alerta-exito';

  return (
    <div className='alertaTodo'>
      {mostrar && (
        <div className={tipoClase}>
          <div className="icon"><i className={icon}></i></div>

          <div className='box-alerta'>
            <strong>{titulo}</strong> <span>{mensaje}</span>
          </div>

          <span className="cerrar" onClick={() => setMostrar(false)}>&times;</span>
        </div>
      )}
    </div>
  );
};

export default Alertas;
