import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import API_BASE_URL from '../apiConfig';
import Validaciones from '../validaciones/Validaciones';
import Alerta from '../funciones/Alertas';
Modal.setAppElement('#root');
const EditarProyecto = ({ isOpen, closeModal, proyectoParaEditar }) => {
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [tipoProyecto, setTipoProyecto] = useState('0');
  const [password, setContrasena] = useState(null);
  const [id_proyecto, setId] = useState('');
    //validaciones ---
    const [errorMensaje, setErrorMensaje] = useState(null);
    const [estadoAlerta, setEstadoAlerta] = useState(null);
    const [error, setError] = useState(null);

  // Si hay un proyecto para editar, establecer los valores iniciales
  useEffect(() => {

    if (proyectoParaEditar) {
      setNombre(proyectoParaEditar.nombre);
      setDescripcion(proyectoParaEditar.descripcion);
      setTipoProyecto(proyectoParaEditar.privado ? '1' : '0');
      setContrasena(proyectoParaEditar.password);
      setId(proyectoParaEditar.id);
      console.log('Datos enviados:', proyectoParaEditar.nombre);
      console.log('Datos enviados:', proyectoParaEditar.password);
    }
  }, [proyectoParaEditar]);

  const handleEditarProyecto = async () => {



      // Realizar las validaciones utilizando la clase Validaciones
      if (!Validaciones.camposObligatorios(nombre, descripcion)) {
      
        setErrorMensaje("Es necesario proporcionar un nombre y una descripción");
        setEstadoAlerta(true);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 4000);
  
        return;
      }
  
      if (!Validaciones.proyectoPrivado(tipoProyecto, password)) {
  
        setErrorMensaje("Es necesario proporcionar una contraseña para su proyecto privado");
        setEstadoAlerta(true);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 4000);
  
       return;
      }


    const requestData = {
      nombre,
      descripcion,
      privado: tipoProyecto,
      password,
      id_proyecto: id_proyecto,
    };

    console.log('Datos enviados:', requestData); // Mostrar los datos que se están enviando en el JSON

    try {
      const response = await fetch(`${API_BASE_URL}/api/proyectos/updateProyecto.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData), // Usar la variable requestData
      });

      const responseData = await response.json(); // Convertir la respuesta a JSON

      console.log('Respuesta recibida:', responseData); // Mostrar la respuesta recibida

      if (response.ok) {
        closeModal();
        window.location.reload();
      } else {
        console.error('Hubo un error al crear el proyecto.');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Mi Ventana Modal"
    >
      <div className="formulario-container">
        <h2>{proyectoParaEditar ? 'Editar Proyecto' : 'Editar Proyecto'}</h2>
        <label>Nombre del Proyecto:</label>
        <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />

        <label>Descripción:</label>
        <textarea value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />

        <label>Tipo de Proyecto:</label>
        <select value={tipoProyecto} onChange={(e) => setTipoProyecto(e.target.value)}>
          <option value="0">Público</option>
          <option value="1">Privado</option>
        </select>

        {tipoProyecto === '1' && (
          <div>
            <label>Contraseña:</label>
            <input type="password" value={password} onChange={(e) => setContrasena(e.target.value)} />
          </div>
        )}
    
        <div className='box-centrar'>
          <button className="boton-izq" onClick={handleEditarProyecto}>
            {proyectoParaEditar ? 'Guardar Cambios' : 'Crear'}
          </button>
          <button onClick={closeModal}>Cerrar</button>
        </div>
        {error && (<Alerta titulo={"¡ERROR!"} mensaje={errorMensaje} tipo="error" icon={"bi bi-exclamation-triangle-fill"} estado={estadoAlerta} />)}
      </div>
    </Modal>
  );
};

export default EditarProyecto;
