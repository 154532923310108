
class Validaciones {



  static camposObligatoriosRegistro(email, contraseña, contraseña2, nombreUsuario) {

    if (!nombreUsuario) {
      return {
        valido: false,
        mensaje: "Por favor, ingresa un nombre de usuario."
      };
    }

    if (!contraseña || !contraseña2) {
      return {
        valido: false,
        mensaje: "Por favor, ingresa una contraseña válida."
      };
    }

    if (contraseña !== contraseña2) {
      return {
        valido: false,
        mensaje: "Las contraseñas no coinciden."
      };
    }

    if (!email || !email.includes('@')) {
      return {
        valido: false,
        mensaje: "Por favor, ingresa un correo electrónico válido."
      };
    }
  
    return {
      valido: true,
    };
  }
  

    /*                LOGIN                       */

    static camposObligatoriosLogin(email, contraseña) {

      if (!email || !email.includes('@')) {
        return {
          valido: false,
          mensaje: "Por favor, ingresa un correo electrónico válido."
        };
      }

      if (!contraseña) {
        return {
          valido: false,
          mensaje: "Por favor, ingresa una contraseña válida."
        };
      }

      return {
        valido: true,
      };
    }

    static camposObligatorios(nombre, descripcion) {
      if (!nombre || !descripcion) {
      //  alert('Por favor, completa el nombre y la descripción del proyecto.');
        return false;
      }
      return true;
    }
  
    static proyectoPrivado(tipoProyecto, password) {
      if (tipoProyecto === '1' && !password) {
        //alert('Por favor, ingresa una contraseña para el proyecto privado.');
        return false;
      }
      return true;
    }

    static camposObligatoriosTargetSonido(nombreTarget, latitude,longitude, file) {
      if (!nombreTarget) {
        return {
          valido: false,
          mensaje: "Por favor complete el nombre del Target"
        };
      }
    
      if (latitude === 0 && longitude === 0) {
        return {
          valido: false,
          mensaje: "Por favor complete los datos de latitud y longitud, (ABRIR MAPA)"
        };
      }
    
      if (!file) {
        return {
          valido: false,
          mensaje: "Por favor seleccione un archivo de audio formato mp3"
        };
      }

      return {
        valido: true,
      };
    }

    static camposObligatoriosTargetImagen(nombreTarget, latitude,longitude, descripcionImg, file) {
      if (!nombreTarget) {
        return {
          valido: false,
          mensaje: "Por favor complete el nombre del Target"
        };
      }
    
      if (latitude === 0 && longitude === 0) {
        return {
          valido: false,
          mensaje: "Por favor complete los datos de latitud y longitud, (ABRIR MAPA)"
        };
      }
    
      if (!file) {
        return {
          valido: false,
          mensaje: "Por favor seleccione una imagen"
        };
      }

      if (!descripcionImg) {
        return {
          valido: false,
          mensaje: "Por favor realice una descripcion"
        };
      }

      return {
        valido: true,
      };
    }

    static camposObligatoriosTargetPDF(nombreTarget,latitude,longitude, file) {
  
      if (!nombreTarget) {
        return {
          valido: false,
          mensaje: "Por favor complete el nombre del Target"
        };
      }

      if (latitude === 0 && longitude === 0) {
        return {
          valido: false,
          mensaje: "Por favor complete los datos de latitud y longitud, (ABRIR MAPA)"
        };
      }
    
      if (!file) {
        return {
          valido: false,
          mensaje: "Por favor seleccione un archivo PDF"
        };
      }

      return {
        valido: true,
      };
    }

    static camposObligatoriosTargetYoutube(nombreTarget,latitude,longitude, linkYoutube) {
  
      if (!nombreTarget) {
        return {
          valido: false,
          mensaje: "Por favor complete el nombre del Target"
        };
      }

      if (latitude === 0 && longitude === 0) {
        return {
          valido: false,
          mensaje: "Por favor complete los datos de latitud y longitud, (ABRIR MAPA)"
        };
      }
    
      if (!linkYoutube) {
        return {
          valido: false,
          mensaje: "Por favor complete con el link de su video"
        };
      }

      return {
        valido: true,
      };
    }

    static camposObligatoriosTargetTexto(nombreTarget,latitude,longitude, tituloTexto, descripcionTexto) {
  
      if (!nombreTarget) {
        return {
          valido: false,
          mensaje: "Por favor complete el nombre del Target"
        };
      }

      if (latitude === 0 && longitude === 0) {
        return {
          valido: false,
          mensaje: "Por favor complete los datos de latitud y longitud, (ABRIR MAPA)"
        };
      }
    
      if (!tituloTexto) {
        return {
          valido: false,
          mensaje: "Por favor complete el titulo de su texto."
        };
      }

      if (!descripcionTexto) {
        return {
          valido: false,
          mensaje: "Por favor complete la descripcion de su texto."
        };
      }

      return {
        valido: true,
      };
    }
    
  }
  
  export default Validaciones;