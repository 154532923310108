import React from 'react';
import HeaderOther from './reutilizable/HeaderOther';
function Estadisticas() {
    return (

        <div>
    <HeaderOther />
    
      <h3>Paginaaa contacto</h3>
      <h3>PRINCIPAAAL PAPA</h3>
      <h3>PRINCIPAAAL PAPA</h3>
      

    </div>
      );
}

export default Estadisticas;