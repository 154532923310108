import HeaderMain from '../reutilizable/HeaderMain';
import '../estilos/Test.css';

function Test() {


    return (

        <div>
        <HeaderMain />


     
 
<div className="contenedor-menu">

       
            <div className="pt-tablecell page-home relative" >
              <div className="overlay" />
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8">
                    <div className="hexagon-menu clear">
                      <div className="hexagon-item">
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <a className="hex-content">
                          <span className="hex-content-inner">
                            <span className="icon">
                              <i className="fa fa-universal-access" />
                            </span>
                            <span className="title">Welcome</span>
                          </span>
                          <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
                        </a>
                      </div>
                      <div className="hexagon-item">
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <a className="hex-content">
                          <span className="hex-content-inner">
                            <span className="icon">
                              <i className="fa fa-bullseye" />
                            </span>
                            <span className="title">About</span>
                          </span>
                          <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
                        </a>
                      </div>
                      <div className="hexagon-item">
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <a className="hex-content">
                          <span className="hex-content-inner">
                            <span className="icon">
                              <i className="fa fa-braille" />
                            </span>
                            <span className="title">Services</span>
                          </span>
                          <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
                        </a>    
                      </div>
                      <div className="hexagon-item">
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <a className="hex-content">
                          <span className="hex-content-inner">
                            <span className="icon">
                              <i className="fa fa-id-badge" />
                            </span>
                            <span className="title">Resume</span>
                          </span>
                          <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
                        </a>
                      </div>
                      <div className="hexagon-item">
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <a className="hex-content">
                          <span className="hex-content-inner">
                            <span className="icon">
                              <i className="fa fa-life-ring" />
                            </span>
                            <span className="title">Works</span>
                          </span>
                          <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
                        </a>
                      </div>
                      <div className="hexagon-item">
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <a className="hex-content">
                          <span className="hex-content-inner">
                            <span className="icon">
                              <i className="fa fa-clipboard" />
                            </span>
                            <span className="title">Testimonials</span>
                          </span>
                          <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
                        </a>
                      </div>
                      <div className="hexagon-item">
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <div className="hex-item">
                          <div />
                          <div />
                          <div />
                        </div>
                        <a className="hex-content">
                          <span className="hex-content-inner">
                            <span className="icon">
                              <i className="fa fa-map-signs" />
                            </span>
                            <span className="title">Contact</span>
                          </span>
                          <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
                        </a>
                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>


    </div>

 

);
}

export default Test; 