
import API_BASE_URL from '../apiConfig';
import '../estilos/ModalStyles.css';
import axios from 'axios';

export const addTargetSonido = async (file, idUsuario, nombreTarget, targetSeleccionado, mapData, idProyecto) => {
  try {
    if (!file || !idUsuario) { // Verificar si hay un archivo seleccionado y si hay un ID de usuario
      alert('Please select a file and provide user ID!');
      return;
    }

    // Verificar si el archivo es un mp3
    if (!file.name.toLowerCase().endsWith('.mp3')) {
      alert('Atencion! Su archivo tiene que ser extencion .mp3');
      return;
    }

    const formData = new FormData();
    formData.append('archivo', file);
    formData.append('usuario_id', idUsuario);
    formData.append('name', nombreTarget);
    formData.append('tipo', targetSeleccionado);
    formData.append('latitude', mapData.latitude);
    formData.append('longitude', mapData.longitude);
    formData.append('id_proyecto', idProyecto);

    const response = await axios.post(`${API_BASE_URL}/api/target/nuevoTarget2.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    const uploadedFileUrl = response.data.url; // Suponiendo que el servidor responde con la URL del archivo
    //console.log('File uploaded successfully!', uploadedFileUrl);
    alert('Su audio se subio con exito!');
    return uploadedFileUrl;
  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};

export const addTargetImagen = async (file, idUsuario, nombreTarget, targetSeleccionado, mapData, idProyecto,descripcionImagen) => {
    try {
      if (!file || !idUsuario) { // Verificar si hay un archivo seleccionado y si hay un ID de usuario
        alert('Please select a file and provide user ID!');
        return;
      }

      // Verificar si el archivo es una de las extensiones permitidas
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];
    const fileExtension = file.name.toLowerCase().substring(file.name.lastIndexOf('.'));
    if (!allowedExtensions.includes(fileExtension)) {
      alert('Atencion! Su archivo debe tener una de las siguientes extensiones: .jpg, .jpeg o .png');
      return;
    }
  
      const formData = new FormData();
      formData.append('archivo', file);
      formData.append('usuario_id', idUsuario);
      formData.append('name', nombreTarget);
      formData.append('tipo', targetSeleccionado);
      formData.append('latitude', mapData.latitude);
      formData.append('longitude', mapData.longitude);
      formData.append('id_proyecto', idProyecto);
      formData.append('descripcionImagen', descripcionImagen);
  
      const response = await axios.post(`${API_BASE_URL}/api/target/addTargetImagen.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      const uploadedFileUrl = response.data.url; // Suponiendo que el servidor responde con la URL del archivo
      //console.log('File uploaded successfully!', uploadedFileUrl);
      alert('Su imagen se subio con exito!');
      return uploadedFileUrl;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };

  export const addTargetPDF = async (file, idUsuario, nombreTarget, targetSeleccionado, mapData, idProyecto) => {
    try {
      if (!file || !idUsuario) { // Verificar si hay un archivo seleccionado y si hay un ID de usuario
        alert('Please select a file and provide user ID!');
        return;
      }
  
      
      // Verificar si el archivo es un PDF
      if (!file.name.toLowerCase().endsWith('.pdf')) {
        alert('Atencion! Su archivo tiene que ser extencion .pdf');
        return;
      }
  
      const formData = new FormData();
      formData.append('archivo', file);
      formData.append('usuario_id', idUsuario);
      formData.append('name', nombreTarget);
      formData.append('tipo', targetSeleccionado);
      formData.append('latitude', mapData.latitude);
      formData.append('longitude', mapData.longitude);
      formData.append('id_proyecto', idProyecto);
  
      const response = await axios.post(`${API_BASE_URL}/api/target/addTargetPDF.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      const uploadedFileUrl = response.data.url; // Suponiendo que el servidor responde con la URL del archivo
      //console.log('File uploaded successfully!', uploadedFileUrl);
      alert('Su PDF se subió con éxito!');
      return uploadedFileUrl;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };