import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import '../Login.css';
import API_BASE_URL from '../apiConfig';
import Validaciones from '../validaciones/Validaciones';
import Alerta from '../funciones/Alertas';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidated, setIsValidated] = useState(false);
  const navigate = useNavigate();

  //validaciones ---
  const [errorMensaje, setErrorMensaje] = useState(null);
  const [estadoAlerta, setEstadoAlerta] = useState(null);
  const [error, setError] = useState(null);

  const handleSignIn = async () => {
     // Realizar las validaciones utilizando la clase Validaciones

     const resultadoValidacion = Validaciones.camposObligatoriosLogin(email, password)
     if (!resultadoValidacion.valido) {
     setErrorMensaje(resultadoValidacion.mensaje);
     setEstadoAlerta(true);
     setError(true);
     setTimeout(() => {
       setError(false);
     }, 4000);
       return;
     }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/users/autenticacionLogin.php`, {
        email: email,
        password: password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = response.data;

      if (response.status === 200 && data.authenticated) {
        setIsValidated(true);

        // Guardar datos en localStorage
        const userData = {
          id: data.user.id,
          nombre_usuario: data.user.nombre_usuario,
          email: data.user.email,
          token: data.user.token
        };
        console.log('ID:', userData.id);
        console.log('Token:', userData.token);
        localStorage.setItem('userData', JSON.stringify(userData));

        Cookies.set('token', data.token);
       // Cookies.set('username', data.user.nombre_usuario);
        navigate('/principal');
      } else {
        setErrorMensaje("La contraseña o el correo electrónico ingresados son incorrectos.");
      setEstadoAlerta(true);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
        // Autenticación fallida
        // ...resto del código...
      }
    } catch (error) {
      // Manejo de errores
      console.error('Error en la solicitud:', error);
      console.log('Error en la solicitud:', error);
    }
  };

  return (
    

    <div className="login-screen">
      <div className="login-form">
        <h2>Iniciar Sesión</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleSignIn}>Iniciar Sesión</button>
        {!isValidated && (
          <p>¿No tienes una cuenta? <Link to="/registro">Regístrate</Link></p>
        )}
      </div>

      {error && (<Alerta titulo={"¡ERROR!"} mensaje={errorMensaje} tipo="error" icon={"bi bi-exclamation-triangle-fill"} estado={estadoAlerta} />)}
     
    </div>

  );
}

export default Login;


/*  <Alerta titulo={"¡ERROR!"} mensaje={errorMensaje} tipo="error" icon={"bi bi-exclamation-triangle-fill"} estado={estadoAlerta} />
*/