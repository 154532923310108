import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../HeaderOther.css';
import '../Styles.css'; 
import Logo from '../../imagenes/logo.png';


/*
<NavLink
                        to="/setting"
                        activeClassName="active" // Clase activa
                        className={`sidebar-link ${activeLink === 'Setting' ? 'active' : ''}`}
                        onClick={() => handleLinkClick('Setting')}
                    >
                        <i className="bi bi-gear"></i>Setting
                    </NavLink>
                    <NavLink
                        to="/estadisticas"
                        activeClassName="active" // Clase activa
                        className={`sidebar-link ${activeLink === 'Estadisticas' ? 'active' : ''}`}
                        onClick={() => handleLinkClick('Estadisticas')}
                    >
                        <i className="bi bi-bar-chart"></i>Estadisticas
                    </NavLink>

 */
function HeaderOther() {
    const [activeLink, setActiveLink] = useState('Home');

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    const storedUserData = localStorage.getItem('userData');
    let nombreUsuario = "Nombre de usuario no disponible"; // Por defecto

    if (storedUserData) {
        const userData = JSON.parse(storedUserData);
        nombreUsuario = userData.nombre_usuario;
    }

    return (
        <>
                      
            <div class="sidebar">
                <div class="sidebar-items">
                    <NavLink
                        to="/principal"
                        activeClassName="active" // Clase activa
                        className={`sidebar-link ${activeLink === 'Principal' ? 'active' : ''}`}
                        onClick={() => handleLinkClick('Principal')}
                    >
                        <i className="bi bi-house"></i>Home
                    </NavLink>
                    <NavLink
                        to="/proyectos"
                        activeClassName="active" // Clase activa
                        className={`sidebar-link ${activeLink === 'Proyectos' ? 'active' : ''}`}
                        onClick={() => handleLinkClick('Proyectos')}
                    >
                        <i className="bi bi-folder"></i>Proyectos
                    </NavLink>
                    
                </div>
            </div>
            
        </>
    );
}

export default HeaderOther;


/*

 <header>
                    <div class="contenedor-logo2">
                        <h1 class="logotexto">GEOWEX</h1>
                    </div>
                    <nav>
                        <ul class='nav_links'>
                            <li><a class="boton-nav" href="/download">Download</a></li>
                            <li><a class="boton-nav" href="/informacion">Informacion</a></li>
                            <li><a class="boton-nav" href="/contacto">Contacto</a></li>
                            <li><a class="boton-nav-2" href="/login">Cerrar Sesion</a></li>
                        </ul>
                    </nav>
                </header>


                */