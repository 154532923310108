
//App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import Principal from './components/Principal';
import Login from './components/utilidades/Login';
import Registro from './components/utilidades/Registro';
import Informacion from './components/home/Informacion';
import ProyectosEjemplos from './components/home/ProyectosEjemplos';
import Download from './components/home/Download';
import Contacto from './components/home/Contacto';
import HeaderMain from './components/reutilizable/HeaderMain';
import HeaderOther from './components/reutilizable/HeaderOther';
import Proyectos from './components/proyectos/Proyectos';
import Setting from './components/Setting';
import Estadisticas from './components/Estadisticas';
import Test from './components/home/Test';

import Targets from './components/proyectos/Targets';
import CrearTarget from './components/proyectos/CrearTarget';

function App() {
  return (

<Router>
        <Routes>


          <Route path="/" element={<Home  />}/>
          <Route path="/login" element={<Login />}/>
          <Route path="/registro" element={<Registro />}/>
          <Route path="/proyectosEjemplos" element={<ProyectosEjemplos/>}/>
          <Route path="/download" element={<Download/>}/>
          <Route path="/contacto" element={<Contacto />}/>
          <Route path="/informacion" element={<Informacion />}/>
          <Route path="/test" element={<Test />}/>

          <Route path="/principal" element={<Principal />}/>
          <Route path="/proyectos" element={<Proyectos />}/>
          <Route path="/setting" element={<Setting />}/>
          <Route path="/estadisticas" element={<Estadisticas />}/>



          <Route path="/targets/:codigo/:id" element={<Targets />} />

        </Routes>
    </Router>



  );
}

export default App;







/*
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import Principal from './components/Principal';
import Login from './components/utilidades/Login';
import Registro from './components/utilidades/Registro';
import Informacion from './components/home/Informacion';
import ProyectosEjemplos from './components/home/ProyectosEjemplos';
import Download from './components/home/Download';
import Contacto from './components/home/Contacto';
import HeaderMain from './components/reutilizable/HeaderMain';
import HeaderOther from './components/reutilizable/HeaderOther';
import Proyectos from './components/proyectos/Proyectos';
import Setting from './components/Setting';
import Estadisticas from './components/Estadisticas';
import Test from './components/home/Test';

import ProtectedRoute from './components/ProtectedRoute';

import Targets from './components/proyectos/Targets';
import CrearTarget from './components/proyectos/CrearTarget';

const App = () => {

  const token = localStorage.getItem('token');

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/registro" element={<Registro />} />
        <Route path="/proyectosEjemplos" element={<ProyectosEjemplos />} />
        <Route path="/download" element={<Download />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/informacion" element={<Informacion />} />
        <Route path="/test" element={<Test />} />
        <Route path="/principal" element={<Principal />} />
        
      
        <ProtectedRoute token={token} path="/proyectos">
          <Route element={<Proyectos />} />
        </ProtectedRoute>
      </Routes>
    </Router>
  );
};

export default App;

*/