import React from 'react';
//import '../Styles.css'; 
import HeaderMain from '../reutilizable/HeaderMain';
import Celular from '../../imagenes/celular.png';
import Computadora from '../../imagenes/computadora.png';
import Foco from '../../imagenes/foco.png';
import img1 from '../../imagenes/img1.png';
import img2 from '../../imagenes/img2.png';
import c1 from '../../imagenes/baner1.png';
import '../estilos/Home.css';
import Header from '../reutilizable/Header';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../imagenes/logo.png';
//<HeaderMain />
//<div class="carrousel-container">

function Home() {

  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    c1,
    img2,
    img1,
  ];

  const imageRefs = [];

  function nextImage(n) {
    setCurrentImage((currentImage + n + images.length) % images.length);
  }

  function currentSlide(n) {
    setCurrentImage(n - 1);
  }
 
//<Link to="/login" className="boton-descarga-apk-2">DESCARGAR APLICACIÓN</Link>
    //const history = useHistory();

    const handleDescargarArchivo = () => {
        // URL directa del archivo que deseas descargar
        const archivoURL = "https://www.geowex.com.ar/apk/app-release.apk"; // Cambia esto por la ruta real del archivo
        // Redirigir al usuario a la URL directa del archivo
        window.location.href = archivoURL;
    };

  return (

 <body>

<div id="mobileContent" className='box-telefono'>
<img className="logo-telefono" src={Logo} alt="Logo de la pagina" />
        <h1 class="logotexto"> GEOWEX </h1>
        <h2 class="h2-telefono">Descargar aplicación</h2>
        <div class="box-telefono-botones">
        <Link className="boton-descarga-android-2" onClick={handleDescargarArchivo}>
            Android
        </Link>
        <Link className="boton-descarga-iOS-2">
            iOS
        </Link>
        </div>
    <p className="parrafo-telefono">Para entrar al modo editor debes ingresar desde una computadora a este mismo link</p>
</div>

    <div id="contenidoPrincipal">
    
    <HeaderMain />

<body>
<div className="container">
{images.map((imageUrl, index) => (
    <div className="image" key={index} style={{ display: currentImage === index ? 'block' : 'none' }}>
      <img src={imageUrl} alt={`Image ${index}`} />
    </div>
  ))}

    <div className="button">
        <button onClick={() => nextImage(-1)} className="prev btn">
          <i className="bi bi-chevron-left"></i> {/* Icono de flecha izquierda */}
        </button>
        <button onClick={() => nextImage(1)} className="next">
          <i className="bi bi-chevron-right"></i> {/* Icono de flecha derecha */}
        </button>
      </div>

      <div className="dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentImage === index ? 'active' : ''}`}
            onClick={() => currentSlide(index + 1)}
          ></span>
        ))}
      </div>
    </div>
    </body>

    <section className='section-home'>
        <article>
            <div class="contenedor-amarillo">
            <figure>
                 <img class="contenedor-imagen" src={Foco} alt="Logo de la pagina" />
            </figure>
            <h2 class="contenedor-h2">Información</h2>
            </div>
        </article>
   
        <article>
            <div class="contenedor-azul">
            <figure>
               <img class="contenedor-imagen" src={Computadora} alt="Logo de la pagina" />
            </figure>
            <h2 class="contenedor-h2">Tutoriales</h2>
            </div>
            
        </article>

        <article>
            <div class="contenedor-rojo">
            <figure>
            <img class="contenedor-imagen" src={Celular} alt="Logo de la pagina" />
            </figure>
            <h2 class="contenedor-h2">Proyectos</h2>
            </div>
            
        </article>

    </section>

    </div>

    </body>

  );
}



export default Home;



/*

<div className="button">
        <button onClick={() => nextImage(-1)} className="prev">
          &#10094;
        </button>
        <button onClick={() => nextImage(1)} className="next">
          &#10095;
        </button>
      </div>


<div class="carrousel-container">
    <div class="carrousel">
        <div class="conteCarrousel">

            <div class="itemCarrousel" id="itemCarrousel-1">
                <div class="itemCarrouselTarjeta"> <img class="img-index" src={test} alt="Logo de la pagina" /></div>
                <div class="itemCarrouselArrows">
                    <a href="#itemCarrousel-2">
                        <i> <img class="img-flecha" src={FlechaIzquierda} alt="Logo de la pagina" /></i>
                    </a>
                </div>
            </div>

            <div class="itemCarrousel" id="itemCarrousel-2">
                <div class="itemCarrouselTarjeta"> <img class="img-index" src={Computadora} alt="Logo de la pagina" /> </div>
                <div class="itemCarrouselArrows">
                    <a href="#itemCarrousel-3">
                        <i> <img class="img-flecha" src={FlechaIzquierda} alt="Logo de la pagina" /></i>
                    </a>
                </div>
            </div>

            <div class="itemCarrousel" id="itemCarrousel-3">
                <div class="itemCarrouselTarjeta"> <img class="img-index" src={Foco} alt="Logo de la pagina" /> </div>
                <div class="itemCarrouselArrows">
                    <a href="#itemCarrousel-1">
                        <i> <img class="img-flecha" src={FlechaIzquierda} alt="Logo de la pagina" /></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="conteCarrouselController">
            <a href="#itemCarrousel-1">•</a>
            <a href="#itemCarrousel-2">•</a>
            <a href="#itemCarrousel-3">•</a>
        </div>
    </div>
 </div>
 
 */