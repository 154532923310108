
import HeaderMain from '../reutilizable/HeaderMain';
import Modal from 'react-modal';
import React, { useState, useEffect } from 'react';
import MapComponent from '../reutilizable/MapComponent';
import API_BASE_URL from '../apiConfig';
import '../estilos/ModalStyles.css';
import Alerta from '../funciones/Alertas';
import Validaciones from '../validaciones/Validaciones';
import '../estilos/Pruebas.css';
//import axios from 'axios';
import { addTargetImagen, addTargetSonido, addTargetPDF } from '../funciones/uploadUtils';


const CrearTarget = ({ isOpen, idProyecto, closeModal }) => {


  const [contenidoMostrado, setContenidoMostrado] = useState(null);
  const [mostrarColumnas, setMostrarColumnas] = useState(true);

  const manejarClicColumna = (contenido) => {
    setContenidoMostrado(contenido);
    setMostrarColumnas(false); // Ocultar las columnas al mostrar el contenido
  };

  const volverAtras = () => {
    setContenidoMostrado(null);
    setMostrarColumnas(true); // Mostrar las columnas al volver atrás
  };


    console.log('Valor de data:', idProyecto);
    const [nombreTarget, setNombreTarget] = useState('');
    const [refreshMap, setRefreshMap] = useState(false); 
    const [targets, setTargets] = useState([]);
    const [targetSeleccionado, setTargetSeleccionado] = useState('nada');
    //const [latitude, setLatitude] = useState('');

    //validaciones ---
    const [errorMensaje, setErrorMensaje] = useState(null);
    const [estadoAlerta, setEstadoAlerta] = useState(null);
    const [error, setError] = useState(null);

    const [tituloTexto, setTituloTexto] = useState('');
    const [descripcionTexto, setDescripcionTexto] = useState('');

    const [linkImagen, setLinkImagen] = useState('');
    const [descripcionImagen, setDescripcionImagen] = useState('');
  
    const [linkPdf, setLinkPdf] = useState('');

    const [linkYoutube, setLinkYoutube] = useState('');

    const [archivo, setLinkAudio] = useState('');

    const [mapData, setMapData] = useState({

          latitude: 0,
          longitude: 0,
        });

    const handleMapDataChange = (newData) => {
            setMapData(newData);
          };

    
          

    const handleSubmit = async (e) => {
            
         //e.preventDefault();
    
            const data = {
                name: nombreTarget,
                tipo: contenidoMostrado,
                latitude: mapData.latitude,
                longitude: mapData.longitude,
                id_proyecto: idProyecto,
                    // Otros datos específicos según el tipo de target

                imagen: linkImagen,
                textoImagen: descripcionImagen,

                titulo: tituloTexto,
                textoNormal: descripcionTexto,

                linkPdf: linkPdf,

                linkYoutube: linkYoutube,

                archivo: archivo,



            };
            console.log('qtrae',{data});
            try {
                const response = await fetch(`${API_BASE_URL}/api/target/nuevoTarget.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
    
                if (response.ok) {
                    console.log('Target creado exitosamente');
                    console.log({data});
                    // Puedes realizar acciones adicionales o cerrar el modal aquí
                    closeModal();
                    window.location.reload();
                } else {
                    console.error('Error al crear el target');
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
        };

        /* ---------------------------------------------------------*/

        

        const [file, setFile] = useState(null);
        const [uploadedUrl, setUploadedUrl] = useState('');
        const [uploadSuccess, setUploadSuccess] = useState(false);
        const [userId, setUserId] = useState('');

        // Obtener el ID del usuario de localStorage
        const userData = JSON.parse(localStorage.getItem('userData'));
        const idUsuario = userData ? userData.id : null;
      
        const handleFileChange = (e) => {
          setFile(e.target.files[0]);
          setUploadSuccess(false); // Resetear el mensaje de éxito al seleccionar un nuevo archivo
        };

          const targetSonido = async () => {
            const uploadedFileUrl = await addTargetSonido(file, idUsuario, nombreTarget, contenidoMostrado, mapData, idProyecto);
            closeModal();
            window.location.reload();
            if (uploadedFileUrl) {
              setUploadedUrl(uploadedFileUrl);
              setUploadSuccess(true); // Indicar que la carga fue exitosa
            }
          };

          const targetImagen = async () => {
            const uploadedFileUrl = await addTargetImagen(file, idUsuario, nombreTarget, contenidoMostrado, mapData, idProyecto,descripcionImagen);
            closeModal();
            window.location.reload();
            if (uploadedFileUrl) {
              setUploadedUrl(uploadedFileUrl);
              setUploadSuccess(true); // Indicar que la carga fue exitosa
            }
          };

          const targetPDF = async () => {
            const uploadedFileUrl = await addTargetPDF(file, idUsuario, nombreTarget, contenidoMostrado, mapData, idProyecto);
            closeModal();
            window.location.reload();
            if (uploadedFileUrl) {
              setUploadedUrl(uploadedFileUrl);
              setUploadSuccess(true); // Indicar que la carga fue exitosa
            }
          };
    

        const customStyles = {
            content: {
              zIndex: 998, // Utiliza camelCase en lugar de guiones
                //background: 'rgb(12 12 12 / 96%)', 
               // background: 'rgb(96%)',// Cambia el color según tus preferencias
            },
        };

    return (
        <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Mi Ventana Modal"
        style={customStyles} // Aplica el estilo personalizado
      >

<div className='target-campo'>

      {/* Renderizar las columnas solo si mostrarColumnas es true */}
      {mostrarColumnas && (
        <section className='sectioTarget'>
          <div className="row-target">
            <h1>Selecciona el formato</h1>
          </div>
          <div className="row-target">
            <div className="column-target" onClick={() => manejarClicColumna('normal')}>
              <div className="card-target">
                <div className="icon">
                  <i class="bi bi-chat-left-text" />
                </div>
                <h3>Texto</h3>
              </div>
            </div>
            <div className="column-target" onClick={() => manejarClicColumna('pdf')}>
              <div className="card-target">
                <div className="icon">
                  <i class="bi bi-filetype-pdf" />
                </div>
                <h3>PDF</h3>
              </div>
            </div>
            <div className="column-target" onClick={() => manejarClicColumna('imagen')}>
              <div className="card-target">
                <div className="icon">
                  <i class="bi bi-image" />
                </div>
                <h3>Imagen</h3>
              </div>
            </div>
          </div>

          <div className="row-target">
          </div>
          <div className="row-target">
            <div className="column-target" onClick={() => manejarClicColumna('sonido')}>
              <div className="card-target">
                <div className="icon">
                  <i class="bi bi-play-circle" />
                </div>
                <h3>Audio</h3>
              </div>
            </div>
            <div className="column-target" onClick={() => manejarClicColumna('encuesta')}>
              <div className="card-target">
                <div className="icon">
                  <i class="bi bi-list-ol" />
                </div>
                <h3>Encuesta</h3>
              </div>
            </div>
            <div className="column-target" onClick={() => manejarClicColumna('youtube')}>
              <div className="card-target">
                <div className="icon">
                  <i class="bi bi-youtube" />
                </div>
                <h3>Video</h3>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Mostrar contenido dependiendo de lo tocado */}
      {contenidoMostrado && (
        <div className="formulario-container">
          <h2>{contenidoMostrado}</h2>
          {/* Mostrar el contenido correspondiente a la columna seleccionada */}
          {contenidoMostrado === 'normal' && (
            <div>
              <input type="text" placeholder="Nombre del Target" value={nombreTarget} onChange={(e) => setNombreTarget(e.target.value)} />
              <input type="text" placeholder="Titulo del texto" value={tituloTexto} onChange={(e) => setTituloTexto(e.target.value)} />
              <textarea type="text" placeholder="Descripción del texto" value={descripcionTexto} onChange={(e) => setDescripcionTexto(e.target.value)} />
              {/* Supongo que MapComponent es un componente personalizado */}
              <MapComponent mapData={mapData} onChange={handleMapDataChange} />
            </div>
          )}
          {contenidoMostrado === 'pdf' && (
            <div>
              <input type="text" placeholder="Nombre del Target" value={nombreTarget} onChange={(e) => setNombreTarget(e.target.value)} />
              {/* Supongo que MapComponent es un componente personalizado */}
              <MapComponent mapData={mapData} onChange={handleMapDataChange} /> 
              <input type="file" onChange={handleFileChange} />
            </div>
          )}
          {contenidoMostrado === 'imagen' && (
            <div>
              <input type="text" placeholder="Nombre del Target" value={nombreTarget} onChange={(e) => setNombreTarget(e.target.value)} />
              {/* Supongo que MapComponent es un componente personalizado */}
              <MapComponent mapData={mapData} onChange={handleMapDataChange} />
              <textarea type="text" placeholder="Descripcion de la imagen" value={descripcionImagen} onChange={(e) => setDescripcionImagen(e.target.value)} />
              <input type="file" onChange={handleFileChange} />
            </div>
          )}
          {contenidoMostrado === 'youtube' && (
             <div> 
             <input type="text" placeholder="Nombre del Target" value={nombreTarget} onChange={(e) => setNombreTarget(e.target.value)} />
             <MapComponent mapData={mapData} onChange={handleMapDataChange} />
             <input type="text" placeholder="Link del video de youtube " value={linkYoutube} onChange={(e) => setLinkYoutube(e.target.value)}/>
         </div>
          )}
          {contenidoMostrado === 'sonido' && (
             <div> 
             <input type="text" placeholder="Nombre del Target" value={nombreTarget} onChange={(e) => setNombreTarget(e.target.value)} />
             <MapComponent mapData={mapData} onChange={handleMapDataChange} />
             <input type="file" onChange={handleFileChange} />
            </div>
          )}
          {contenidoMostrado === 'encuesta' && (
             <div> 
             <h3>No disponible</h3>
            </div>
          )}

          <div className='box-centrar'>
          <button onClick={volverAtras}>Volver Atrás</button>

          <button onClick={() => {
    if (contenidoMostrado === null) {
      setErrorMensaje("Por favor, seleccione una opción antes de continuar.");
      setEstadoAlerta(true);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
      return;
    }
    else if (contenidoMostrado === 'sonido') {
      const resultadoValidacion = Validaciones.camposObligatoriosTargetSonido(nombreTarget, mapData.latitude,mapData.longitude, file)
      if (!resultadoValidacion.valido) {
      setErrorMensaje(resultadoValidacion.mensaje);
      setEstadoAlerta(true);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
        return;
      } 
      targetSonido();

    } else if (contenidoMostrado === 'imagen') {
      const resultadoValidacion = Validaciones.camposObligatoriosTargetImagen(nombreTarget, mapData.latitude,mapData.longitude,descripcionImagen, file)
      if (!resultadoValidacion.valido) {
      setErrorMensaje(resultadoValidacion.mensaje);
      setEstadoAlerta(true);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
        return;
      } 
      targetImagen();

    } else if (contenidoMostrado === 'pdf') {
      const resultadoValidacion = Validaciones.camposObligatoriosTargetPDF(nombreTarget,mapData.latitude,mapData.longitude, file)
      if (!resultadoValidacion.valido) {
      setErrorMensaje(resultadoValidacion.mensaje);
      setEstadoAlerta(true);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
        return;
      } 
      targetPDF();

    } else if (contenidoMostrado === 'youtube') {
      const resultadoValidacion = Validaciones.camposObligatoriosTargetYoutube(nombreTarget,mapData.latitude,mapData.longitude, linkYoutube)
      if (!resultadoValidacion.valido) {
      setErrorMensaje(resultadoValidacion.mensaje);
      setEstadoAlerta(true);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
        return;
      } 
      handleSubmit();
    } else if (contenidoMostrado=== 'normal') {
      const resultadoValidacion = Validaciones.camposObligatoriosTargetTexto(nombreTarget,mapData.latitude,mapData.longitude, tituloTexto,descripcionTexto)
      if (!resultadoValidacion.valido) {
      setErrorMensaje(resultadoValidacion.mensaje);
      setEstadoAlerta(true);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
        return;
      } 
      handleSubmit();
    }
  }}>
    Crear
  </button>


  </div>
        </div>
      )}
    

        
<div className='box-centrar'>
  <button onClick={closeModal}>TARGETS</button>
  {error && (<Alerta titulo={"¡ERROR!"} mensaje={errorMensaje} tipo="error" icon={"bi bi-exclamation-triangle-fill"} estado={estadoAlerta} />)}
</div>

</div>
      </Modal>
      );
}

export default CrearTarget;


/*

 {targetSeleccionado === 'sonido' && (
           <div> 

                <div className="contacto-window">
                <input type="text" placeholder="Nombre del Target" value={nombreTarget} onChange={(e) => setNombreTarget(e.target.value)} />
                <MapComponent mapData={mapData} onChange={handleMapDataChange} />
      <input type="file" onChange={handleFileChange} />
      {uploadedUrl && (
        <div>
          <p>File uploaded: <a href={uploadedUrl} target="_blank" rel="noopener noreferrer">{uploadedUrl}</a></p>
        </div>
      )}
    </div>

                

            </div>




*/