import React from 'react';
import HeaderOther from './reutilizable/HeaderOther';
function Setting() {
    return (

        <div>
    <HeaderOther />
    
      <h3>Paginaaa contacto</h3>

    </div>
      );
}

export default Setting;