import React, { useState, useEffect } from 'react';
import Alerta from '../funciones/Alertas';
import API_BASE_URL from '../apiConfig'; // Asumiendo que este es el archivo donde está definida la URL base de la API
import axios from 'axios';
import '../estilos/Pruebas.css';
import MapComponent from '../reutilizable/MapComponent';

const Contacto = () => {
  const [error, setError] = useState(null);
  const [estado, setEstadoAlerta] = useState(null);
  const [error2, setError2] = useState(null);

  const [contenidoMostrado, setContenidoMostrado] = useState(null);
  const [mostrarColumnas, setMostrarColumnas] = useState(true);

  const [nombreTarget, setNombreTarget] = useState('');
  const [tituloTexto, setTituloTexto] = useState('');
  const [descripcionTexto, setDescripcionTexto] = useState('');
  const [descripcionImagen, setDescripcionImagen] = useState('');
  const [mapData, setMapData] = useState(null); // Supongo que mapData es una variable de estado

  const manejarClicColumna = (contenido) => {
    setContenidoMostrado(contenido);
    setMostrarColumnas(false); // Ocultar las columnas al mostrar el contenido
  };

  const volverAtras = () => {
    setContenidoMostrado(null);
    setMostrarColumnas(true); // Mostrar las columnas al volver atrás
  };

  // Función para manejar cambios en mapData
  const handleMapDataChange = (data) => {
    setMapData(data);
  };

  // Función para manejar cambios en el archivo
  const handleFileChange = (e) => {
    // Código para manejar el cambio en el archivo
  };

  const manejarError = () => {
    setEstadoAlerta(true);
    setError(true);
    
    // Establecer un retraso de 4 segundos antes de cambiar el estado de error nuevamente
    setTimeout(() => {
      setError(false);
    }, 4000);
  };

  const manejarExito = () => {
    setEstadoAlerta(true);
    setError2(true);

    setTimeout(() => {
      setError2(false);
    }, 4000);
  };

  return (
    <div>
      <h1>Contacto</h1>
      <Alerta titulo={"¡JOJO!"} mensaje={"EJOPOJa"} tipo="exito" estado={estado} />
      {error && <Alerta titulo={"¡ERROR!"} mensaje={"Manejala como puedas"} tipo="error" estado={estado} />} {/* Muestra la alerta de error si hay un error */}
      {error2 && <Alerta titulo={"¡eX!"} mensaje={"Exito pa"} tipo="exito" estado={estado} />} {/* Muestra la alerta de éxito si hay un éxito */}
      <button onClick={manejarError}>Mostrar Alerta de Error</button> {/* Botón para llamar a la alerta de error */}
      <button onClick={manejarExito}>Mostrar Alerta de Éxito</button> {/* Botón para llamar a la alerta de éxito */}
    

    <div class="alert alert-danger alert-dismissable fade in">
    <button type="button" data-dismiss="alert" aria-label="close" class="close"><span aria-hidden="true">×</span></button><strong>Oh snap!</strong> Change a few things up and try submitting again.</div>
    
    <div class="confirmation-container">
  <p class="confirmation-text">¿Aceptar borrar X cosa?</p>
  <div class="confirmation-buttons">
    <button class="confirmation-button confirmation-button-accept">Aceptar</button>
    <button class="confirmation-button confirmation-button-cancel">Cancelar</button>
  </div>
  </div>



  <>
      {/* Renderizar las columnas solo si mostrarColumnas es true */}
      {mostrarColumnas && (
        <section>
          <div className="row">
            <h1>Our Features</h1>
          </div>
          <div className="row">
            <div className="column" onClick={() => manejarClicColumna('User Friendly')}>
              <div className="card">
                <div className="icon">
                  <i className="fa-solid fa-user" />
                </div>
                <h3>User Friendly</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
                  asperiores natus ad molestiae aliquid explicabo. Iste eaque quo et
                  commodi.
                </p>
              </div>
            </div>
            <div className="column" onClick={() => manejarClicColumna('Super Secure')}>
              <div className="card">
                <div className="icon">
                  <i className="fa-solid fa-shield-halved" />
                </div>
                <h3>Super Secure</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
                  asperiores natus ad molestiae aliquid explicabo. Iste eaque quo et
                  commodi.
                </p>
              </div>
            </div>
            <div className="column" onClick={() => manejarClicColumna('Quick Support')}>
              <div className="card">
                <div className="icon">
                  <i className="fa-solid fa-headset" />
                </div>
                <h3>Quick Support</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
                  asperiores natus ad molestiae aliquid explicabo. Iste eaque quo et
                  commodi.
                </p>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Mostrar contenido dependiendo de lo tocado */}
      {contenidoMostrado && (
        <div className="contenido-mostrado">
          <h2>{contenidoMostrado}</h2>
          {/* Mostrar el contenido correspondiente a la columna seleccionada */}
          {contenidoMostrado === 'User Friendly' && (
            <div>
              <input type="text" placeholder="Nombre del Target" value={nombreTarget} onChange={(e) => setNombreTarget(e.target.value)} />
              <input type="text" placeholder="Titulo del texto" value={tituloTexto} onChange={(e) => setTituloTexto(e.target.value)} />
              <textarea type="text" placeholder="Descripción del texto" value={descripcionTexto} onChange={(e) => setDescripcionTexto(e.target.value)} />
              {/* Supongo que MapComponent es un componente personalizado */}
              <MapComponent mapData={mapData} onChange={handleMapDataChange} />
            </div>
          )}
          {contenidoMostrado === 'Super Secure' && (
            <div>
              <input type="text" placeholder="Nombre del Target" value={nombreTarget} onChange={(e) => setNombreTarget(e.target.value)} />
              {/* Supongo que MapComponent es un componente personalizado */}
              <MapComponent mapData={mapData} onChange={handleMapDataChange} /> 
              <input type="file" onChange={handleFileChange} />
            </div>
          )}
          {contenidoMostrado === 'Quick Support' && (
            <div>
              <input type="text" placeholder="Nombre del Target" value={nombreTarget} onChange={(e) => setNombreTarget(e.target.value)} />
              {/* Supongo que MapComponent es un componente personalizado */}
              <MapComponent mapData={mapData} onChange={handleMapDataChange} />
              <textarea type="text" placeholder="Descripcion de la imagen" value={descripcionImagen} onChange={(e) => setDescripcionImagen(e.target.value)} />
              <input type="file" onChange={handleFileChange} />
            </div>
          )}
          <button onClick={volverAtras}>Volver Atrás</button>
        </div>
      )}
    </>



    </div>
 
  );
};

export default Contacto;
