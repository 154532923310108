import React from 'react';
import HeaderOther from './reutilizable/HeaderOther';
import Header from './reutilizable/Header';

function Principal() {
  const storedUserData = localStorage.getItem('userData');
  let userDataString = "Datos del usuario no disponibles"; // Por defecto

  if (storedUserData) {
    const userData = JSON.parse(storedUserData);
    userDataString = JSON.stringify(userData, null, 2); // Formateado con sangría de 2 espacios
  }


  /* <pre>{userDataString}</pre> datos del usuario */
  return (
  

    

    <main class='main-cuerpo'> 
      <Header />
    <div class='cuerpo-principal'>
      <HeaderOther />
    <div class='cuerpo-datos'>

    <div class="box-principal-home">

      <div class="contenedor-menu">

       
        <div class="pt-tablecell page-home relative" >
  <div class="overlay" />
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8">
        <div class="hexagon-menu clear">
          <div class="hexagon-item">
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <a class="hex-content">
              <span class="hex-content-inner">
                <span class="icon">
                  <i class="bi bi-house" />
                </span>
                <span class="title">Home</span>
              </span>
              <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
            </a>
          </div>
          <div class="hexagon-item">
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <a class="hex-content">
              <span class="hex-content-inner">
                <span class="icon">
                  <i class="bi bi-cone-striped" />
                </span>
                <span class="title">Tutoriales</span>
              </span>
              <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
            </a>
          </div>
          <div class="hexagon-item">
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <a class="hex-content">
              <span class="hex-content-inner">
                <span class="icon">
                  <i class="bi bi-reception-4" />
                </span>
                <span class="title">Estadisticas</span>
              </span>
              <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
            </a>    
          </div>
          <div class="hexagon-item">
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <a class="hex-content">
              <span class="hex-content-inner">
                <span class="icon">
                  <i class="bi bi-envelope" />
                </span>
                <span class="title">Mensajes</span>
              </span>
              <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
            </a>
          </div>
          <div class="hexagon-item">
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <a class="hex-content">
              <span class="hex-content-inner">
                <span class="icon">
                  <i class="bi bi-star-fill" />
                </span>
                <span class="title">Titulo 1</span>
              </span>
              <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
            </a>
          </div>
          <div class="hexagon-item">
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <a class="hex-content">
              <span class="hex-content-inner">
                <span class="icon">
                  <i class="bi bi-qr-code" />
                </span>
                <span class="title">Titulo 2</span>
              </span>
              <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
            </a>
          </div>
          <div class="hexagon-item">
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <div class="hex-item">
              <div />
              <div />
              <div />
            </div>
            <a class="hex-content">
              <span class="hex-content-inner">
                <span class="icon">
                  <i class="bi bi-people-fill" />
                </span>
                <span class="title">Titulo 3</span>
              </span>
              <svg viewBox="0 0 173.20508075688772 200" height={200} width={174} version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530" /></svg>
            </a>
          </div>
        </div>

        
      </div>
    </div>
  </div>
        </div>

        </div>



    </div>

    </div>  

    </div>
    </main>

  

  );
}

export default Principal;