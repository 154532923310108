import React from 'react';
import Logo from '../../imagenes/logo.png';
import { Link } from 'react-router-dom';
import '../Styles.css'; 
// <li><Link to="/contacto" className="boton-nav">Contacto</Link></li>
function HeaderMain() {
  return (
    <header className='header-2'>
      <Link to="/" className="contenedor-logo">
    
        <img className="logo" src={Logo} alt="Logo de la pagina" />
        <h1 className="logotexto" >GEOWEX</h1>
    
      </Link>
      <nav>
        <ul className='nav_links'>
          <li><Link to="/download" className="boton-nav">Download</Link></li>
          <li><Link to="/proyectosEjemplos" className="boton-nav">Proyectos</Link></li>
          <li><Link to="/informacion" className="boton-nav">Informacion</Link></li>
          <li><Link to="/login" className="boton-nav-2">Login</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default HeaderMain;




/*import React from 'react';
import Logo from '../../imagenes/logo.png';
import { Link } from 'react-router-dom';
import '../Styles.css'; 

function HeaderMain() {
  return (
    
    <header className='header-2'>
        <div class="contenedor-logo">
    <img class="logo" src={Logo} alt="Logo de la pagina" />
    <h1 class="logotexto" >GEOWEX</h1>
        </div>
    <nav>
      <ul class='nav_links'>
        <li><a class="boton-nav" href="/download">Download</a></li>
        <li><a class="boton-nav" href="/informacion">Informacion</a></li>
        <li><a class="boton-nav" href="/contacto">Contacto</a></li>
        <li><a class="boton-nav-2" href="/login">Login</a></li>
      </ul>
    </nav>
    </header>  
    
  );
}

export default HeaderMain;*/