const API_BASE_URL = 'https://www.geowex.com.ar';

export default API_BASE_URL;


// import API_BASE_URL from './apiConfig';
// const apiUrl = `${API_BASE_URL}/posts`;

//https://geowex.com.ar
//https://www.geowex.net
//https://www.geowex.com.ar
//http://192.168.1.120
//https://all-day-toolbox.000webhostapp.com
//https://pleural-feedback.000webhostapp.com/

