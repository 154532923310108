import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapComponent = ({ mapData, onChange }) => {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [showMap, setShowMap] = useState(false);
  
    const handleMapClick = (e) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
  
      setSelectedLocation({ lat, lng });
      setLatitude(lat.toFixed(6));
      setLongitude(lng.toFixed(6));


      const newMapData = {
        latitude: lat.toFixed(6),
        longitude: lng.toFixed(6),
      };
      console.log('Valores enviados:', {newMapData });
      onChange(newMapData);
      
    };
  
    const handleMarkerClick = () => {
      setSelectedLocation(null);
    };
  
    const handleCloseMap = () => {
      setShowMap(false);
    };
  
    const handleToggleMap = () => {
      setShowMap(!showMap);
    };



   /* const handleMapDataUpdate = () => {
      // Aquí puedes actualizar los datos del mapa
      const newMapData = {
        latitude,
        longitude,
      };
      onChange(newMapData); // Actualiza los datos en el componente padre
    };*/

  return (
    <div >
    <label>Seleccionar Ubicación:</label>
    <button onClick={() => setShowMap(true)}>Abrir Mapa</button>
    <label>Latitud:</label>
    <input type="text" value={latitude} onChange={(e) => setLatitude(e.target.value)} />

    <label>Longitud:</label>
    <input type="text" value={longitude} onChange={(e) => setLongitude(e.target.value)} />

    {showMap && (
      <div className="map-container">
        <button onClick={handleCloseMap}>Cerrar Mapa</button>
        <LoadScript googleMapsApiKey="AIzaSyDVRT0YoWSoFfUr8ZCQw12ecWyAOAQQHbo">
          <GoogleMap
            mapContainerStyle={{ height: '400px', width: '100%' }}
            center={selectedLocation || { lat: -40.813144, lng: -62.988500 }}
            zoom={8}
            onClick={handleMapClick}
          >
            {selectedLocation && (
              <Marker position={selectedLocation} />
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    )}
  </div>
  );
};

export default MapComponent;