import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import '../Login.css';
import API_BASE_URL from '../apiConfig';
import Validaciones from '../validaciones/Validaciones';
import Alerta from '../funciones/Alertas';

function Registro() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [usuario, setNombreUsuario] = useState('');
  const navigate = useNavigate();
  //validaciones ---
  const [errorMensaje, setErrorMensaje] = useState(null);
  const [estadoAlerta, setEstadoAlerta] = useState(null);
  const [error, setError] = useState(null);

  const handleRegistro = async () => {

    const resultadoValidacion = Validaciones.camposObligatoriosRegistro(email, password,password2,usuario)
     if (!resultadoValidacion.valido) {
     setErrorMensaje(resultadoValidacion.mensaje);
     setEstadoAlerta(true);
     setError(true);
     setTimeout(() => {
       setError(false);
     }, 4000);
       return;
     }


    const requestData = {
        usuario,
        email,
        password,
      };
      console.log('Datos enviados:', requestData);
      try {
        const response = await fetch(`${API_BASE_URL}/api/users/registrarUsuario.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData), // Usar la variable requestData
        });
  
        const responseData = await response.json(); // Convertir la respuesta a JSON
        console.log('Datos enviados:', requestData);
        console.log('Respuesta recibida:', responseData); // Mostrar la respuesta recibida
  
        if (response.ok) {
            navigate('/principal');
        } else {
          console.error('Hubo un error al crear el usuario');
        }
      } catch (error) {
        console.error('Error al hacer la solicitud:', error);
      }
    };



  return (
    <div className="login-screen">
      <div className="login-form">
        <h2>Registro</h2>
        <input
          type="text"
          placeholder="Nombre de usuario"
          value={usuario}
          onChange={(e) => setNombreUsuario(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirmación Password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
        <input
          type="text"
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handleRegistro}>Registrar Usuario</button>
      </div>
      {error && (<Alerta titulo={"¡ERROR!"} mensaje={errorMensaje} tipo="error" icon={"bi bi-exclamation-triangle-fill"} estado={estadoAlerta} />)}
    </div>
  );
}

export default Registro;
