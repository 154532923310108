import React from 'react';
import HeaderMain from '../reutilizable/HeaderMain';
function Download() {
    

  return (

    <div>
    <HeaderMain />
    
      <h3>Paginaaa Download</h3>

    </div>
  );
}

export default Download;