import React from 'react';
import Logo from '../../imagenes/logo.png';
import { Link } from 'react-router-dom';
import '../Styles.css'; 

function Header() {
  return (
    <header>
    <div class="contenedor-logo2">
        <h1 class="logotexto">GEOWEX</h1>
    </div>
    <nav>
        <ul class='nav_links'>
            <li><a class="boton-nav-2" href="/login">Cerrar Sesion</a></li>
        </ul>
    </nav>
</header>
  );
}

export default Header;

/*
<li><a class="boton-nav" href="/download">Download</a></li>
            <li><a class="boton-nav" href="/informacion">Informacion</a></li>
            <li><a class="boton-nav" href="/contacto">Contacto</a></li>

 */


/*import React from 'react';
import Logo from '../../imagenes/logo.png';
import { Link } from 'react-router-dom';
import '../Styles.css'; 

function HeaderMain() {
  return (
    
    <header className='header-2'>
        <div class="contenedor-logo">
    <img class="logo" src={Logo} alt="Logo de la pagina" />
    <h1 class="logotexto" >GEOWEX</h1>
        </div>
    <nav>
      <ul class='nav_links'>
        <li><a class="boton-nav" href="/download">Download</a></li>
        <li><a class="boton-nav" href="/informacion">Informacion</a></li>
        <li><a class="boton-nav" href="/contacto">Contacto</a></li>
        <li><a class="boton-nav-2" href="/login">Login</a></li>
      </ul>
    </nav>
    </header>  
    
  );
}

export default HeaderMain;*/