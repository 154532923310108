import React, { useState } from 'react';
import Modal from 'react-modal';
import API_BASE_URL from '../apiConfig';
import Validaciones from '../validaciones/Validaciones';
import Alerta from '../funciones/Alertas';
//import { ToastContainer, toast } from 'react-toastify';
// npm install @mui/material @emotion/react @emotion/styled react-scripts
const CrearProyecto = ({ isOpen, closeModal }) => {
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [tipoProyecto, setTipoProyecto] = useState('0');
  const [password, setContrasena] = useState(null);

  //validaciones ---
  const [errorMensaje, setErrorMensaje] = useState(null);
  const [estadoAlerta, setEstadoAlerta] = useState(null);
  const [error, setError] = useState(null);

  // Obtener datos del usuario desde localStorage
  const userData = JSON.parse(localStorage.getItem('userData'));
  const idUsuario = userData.id;

  const generarCodigoAleatorio = () => {
    const codigo = Math.floor(1000 + Math.random() * 9000); // Generar un número aleatorio de 4 dígitos
    return codigo.toString();
  };

  const handleCrearProyecto = async () => {


     // Realizar las validaciones utilizando la clase Validaciones
    if (!Validaciones.camposObligatorios(nombre, descripcion)) {
      
      setErrorMensaje("Es necesario proporcionar un nombre y una descripción");
      setEstadoAlerta(true);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);

      return;
    }

    if (!Validaciones.proyectoPrivado(tipoProyecto, password)) {

      setErrorMensaje("Es necesario proporcionar una contraseña para su proyecto privado");
      setEstadoAlerta(true);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);

     return;
    }

    const codigoAleatorio = generarCodigoAleatorio();

    const requestData = {
      nombre,
      descripcion,
      privado: tipoProyecto,
      password,
      id_user: idUsuario,
      codigo: codigoAleatorio,
    };

    console.log('Datos enviados:', requestData); // Mostrar los datos que se están enviando en el JSON

    try {
      const response = await fetch(`${API_BASE_URL}/api/proyectos/crearProyecto.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData), // Usar la variable requestData
      });

      const responseData = await response.json(); // Convertir la respuesta a JSON
      console.log('Datos enviados:', requestData);
      console.log('Respuesta recibida:', responseData); // Mostrar la respuesta recibida

      if (response.ok) {
        closeModal();
        window.location.reload();
      } else {
        console.error('Hubo un error al crear el proyecto.');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
    }
  };

  const customStyles = {
    content: {
        background: 'rgb(12 12 12 / 96%)',// Cambia el color según tus preferencias
    },
    };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Mi Ventana Modal"
      style={customStyles}
    >
      <div className="formulario-container">
        <h2>Crear Proyecto</h2>
        <label>Nombre del Proyecto:</label>
        <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />

        <label>Descripción:</label>
        <textarea value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />

        <label>Tipo de Proyecto:</label>
        <select value={tipoProyecto} onChange={(e) => setTipoProyecto(e.target.value)}>
          <option value="0">Público</option>
          <option value="1">Privado</option>
        </select>

        {tipoProyecto === '1' && (
          <div>
            <label>Contraseña:</label>
            <input type="password"  onChange={(e) => setContrasena(e.target.value)} />
          </div>
        )}
   
    <div className='box-centrar'>
        <button className="boton-izq" onClick={handleCrearProyecto}>Crear</button>
        <button onClick={closeModal}>Cerrar</button>
    </div>
    {error && (<Alerta titulo={"¡ERROR!"} mensaje={errorMensaje} tipo="error" icon={"bi bi-exclamation-triangle-fill"} estado={estadoAlerta} />)}
      </div>
    </Modal>
  );
};

export default CrearProyecto;
