import React, { useState, useEffect } from 'react';
import HeaderOther from '../reutilizable/HeaderOther';
import Header from '../reutilizable/Header';
import '../Styles-Lista.css';
import CrearProyecto from './CrearProyecto';
import EditarProyecto from './EditarProyecto'; // Importa el componente de edición
import { Link } from 'react-router-dom';
import API_BASE_URL from '../apiConfig';
import '../Styles.css';

function Proyectos() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null); // Nuevo estado
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Nuevo estado

  const borrarProyecto = (projectId) => {
    const confirmacion = window.confirm('¿Estás seguro de que quieres borrar el proyecto?');
  
    if (confirmacion) {
      fetch(`${API_BASE_URL}/api/proyectos/borrarProyecto.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_proyecto: projectId }),
      })
        .then(response => response.json())
        .then(data => {
          console.log(data); // Puedes mostrar un mensaje o hacer cualquier otra acción con la respuesta de la API
  
          // Actualiza la página después de borrar el proyecto
          window.location.reload();
        })
        .catch(error => {
          console.error('Error al borrar el proyecto:', error);
        });
    }
  };
  
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openEditModal = (project) => {
    setSelectedProject(project);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Obtiene el ID de usuario del localStorage
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.id;

    // Realiza una solicitud GET para obtener los datos de los proyectos
    fetch(`${API_BASE_URL}/api/proyectos/proyectosUser.php?userId=${userId}`)
      .then(response => response.json())
      .then(data => {
        // Actualiza el estado con los datos obtenidos
        setProjects(data);
      })
      .catch(error => {
        console.error('Error al obtener los proyectos:', error);
      });
  }, []);

  return (



    <main className='main-cuerpo'> 
    <Header/>
    <div className='cuerpo-principal'>

    <HeaderOther />

    <div className='cuerpo-datos'>
  


    <div className='box-botones-arriba'>

    <button className="button-verde" onClick={openModal}>
      <span className="button__text">Crear proyecto</span>
      <span className="button__icon">
        <i className="bi bi-folder-plus"></i>
      </span>
    </button>
    <CrearProyecto isOpen={isModalOpen} closeModal={closeModal} />

    <button className="button-naranja" /*onClick={openModal}*/>
      <span className="button__text">Colaborativo</span>
      <span className="button__icon">
        <i className="bi-universal-access"></i>
      </span>
    </button>
   {/*  <CrearProyecto isOpen={isModalOpen} closeModal={closeModal} /> */}       

    </div>

  <div className='box-flex'>
  <div>

    <div className='tabla-container'>
    <table className='table-normal'>
        <thead className='titulos-barra-tabla'>
          <tr>
          <th>Codigo</th>
          <th>Nombre</th>
          <th>Descripcion</th>
          <th>Status</th>
          <th>Editar</th>
          <th>Targets</th>
          <th>Borrar</th>
          </tr>
        </thead>
        <tbody className='tabla-contexto'>
        {projects.map(project => (
          <tr key={project.id}>
            <td>
              <a href="#">{project.codigo}</a>
            </td>
            <td className='elemento-oculto'>{project.password}</td>
            <td>{project.nombre}</td>
            <td>{project.descripcion.substring(0, 20)}</td> {/* Limitar a 20 caracteres */}
            <td>
              <p className={`status ${project.privado ? 'status-unpaid' : 'status-paid'}`}>
                {project.privado ? 'Privado' : 'Público'}
              </p>
            </td>
            <td>
              <button
                type="button"
                className="boton-lista-verde"
                onClick={() => openEditModal(project)}
              >
                <i className="bi bi-pencil"></i>
              </button>
            </td>
            <td>
             <Link to={`/targets/${project.codigo}/${project.id}`} className="boton-lista-target" ><i className="bi-geo-alt" style={{ color: '#edf0f1' }}></i></Link>
            </td>
            <td>
                  <button onClick={() => borrarProyecto(project.id)} type="button" className="boton-lista-rojo"> <i className="bi-trash3-fill"></i>
                </button>
               </td>
          </tr>
        ))}
      
      </tbody>
      </table>


    </div>
    </div>

      

</div>
    {/* Renderiza la ventana modal de edición */}
    {selectedProject && (
      <EditarProyecto
        isOpen={isEditModalOpen}
        closeModal={closeEditModal}
        proyectoParaEditar={selectedProject}
      />
    )}
  </div>
  </div>
  </main>
  );
}

export default Proyectos;
