import React from 'react';
import HeaderMain from '../reutilizable/HeaderMain';
import Celular from '../../imagenes/celular.png';
import Computadora from '../../imagenes/computadora.png';
import Foco from '../../imagenes/foco.png';
import Educacion from '../../imagenes/educacion.jpg';
import Agronomia from '../../imagenes/agronomia.jpg';
import Turismo from '../../imagenes/turismo.jpg';
import Proyectos from '../../imagenes/proyectos.jpg';
import Imagen1 from '../../imagenes/informacion1.png';
import Imagen2 from '../../imagenes/informacion2.png';
import Imagen3 from '../../imagenes/informacion3.png';
import Imagen4 from '../../imagenes/informacion4.png';
import '../estilos/Informacion.css';

function Informacion() {
    return (

        <div>
        <HeaderMain />

<main className='main-informacion'>
          
  <h1 className='h1-informacion'>GEOWEX</h1>

    <h2 className='h2-informacion'>INTRODUCCIÓN:</h2>
    <p className='p-informacion'>Es una aplicación que brinda la posibilidad de crear mapas interactivos personalizados, sin requerir conocimientos avanzados en programación. La aplicación se centra en la simplicidad, permitiéndote generar proyectos de geo posicionamiento personalizados desde tu navegador web. En el modo (editor/pagina web), puedes vincular diversos contenidos multimedia a tus proyectos. Una vez creados, cualquier persona que descargue la aplicación móvil puede explorar y disfrutar de los recorridos generados en el modo (usuario/aplicacion movil). 
    </p>

    <div class="contenedor-imagen-informacion-cuerpo">
    <img  src={Imagen1} alt="Imagen explicando geowex" />
    </div>

    <h2 className='h2-informacion'>MODO EDICIÓN (WEB):</h2>

    <p className='p-informacion'>El Modo Edición se lleva a cabo a través del sitio web www.geowex.com.ar, donde los usuarios pueden registrarse para acceder a la creación de proyectos personalizados. La plataforma web ofrece un panel intuitivo que permite a los usuarios generar diversos proyectos con facilidad. Dentro de estos proyectos, los usuarios pueden crear múltiples puntos de referencia o interés. </p>
    
    <p className='p-informacion'>Estos puntos de referencia son completamente personalizables y el usuario puede elegir entre varios tipos de disparadores, como imágenes, audio, video, encuestas o texto, para cada uno de ellos. Cada punto de referencia se asocia con una ubicación específica en el mapa, permitiendo al usuario definir dónde desea que los participantes lleguen para acceder a la información relacionada.</p>

    <p className='p-informacion'>Por ejemplo, si en una ciudad existe un monumento histórico, al crear un proyecto de recorrido histórico, se puede establecer un punto de referencia para este monumento, adjuntando la ubicación exacta y un tipo de disparador, como un video que explica la historia del monumento. Cuando un usuario utilice la aplicación móvil y esté lo suficientemente cerca del monumento, la aplicación activará el disparador asociado, permitiendo al usuario acceder al video. Si el usuario se aleja del monumento, la aplicación desactivará automáticamente ese punto de referencia.</p>
    
    <p className='p-informacion'>Esta versatilidad permite a los usuarios crear una amplia gama de puntos en un proyecto, como por ejemplo, un recorrido histórico, artístico o turístico. La aplicación garantiza una experiencia interactiva y envolvente, donde los usuarios pueden explorar información relevante según su ubicación geográfica, ofreciendo así una experiencia dinámica y única en cada proyecto creado. </p>

    <h2 className='h2-informacion'>MODO USUARIO (Aplicación móvil):</h2>

    <p className='p-informacion'>La magia comienza cuando compartes tu proyecto con otros. Cualquier persona con un dispositivo móvil en el que se haya descargado la aplicación, puede abrirlo y experimentar tu viaje personalizado, explorando todos los detalles y recursos que has incluido.</p>

    <p className='p-informacion'>Si una persona no se encuentra cerca de ningún punto de referencia en el mapa, la aplicación no activará ningún disparador. En este escenario, cuando el usuario se encuentre fuera del rango de proximidad establecido para los puntos de referencia, la aplicación permanecerá inactiva en cuanto a la activación de cualquier contenido asociado. Esto significa que si el usuario está distante de todos los puntos de interés previamente definidos en el mapa, no se desencadenará ningún tipo de información adicional o contenido multimedia. La aplicación espera la proximidad o la ubicación cercana a un punto específico para activar y ofrecer contenido relevante, manteniendo así una experiencia controlada y focalizada en la información correspondiente a cada ubicación específica dentro del proyecto</p>

    <div class="contenedor-imagen-informacion-cuerpo">
    <img  src={Imagen4} alt="Imagen explicando geowex" />
    </div>

    <p className='p-informacion'>Cuando una persona se acerca a un punto de referencia definido en el mapa, la aplicación activará el disparador asociado a dicho punto. Esto ocurre cuando el usuario se encuentra dentro del rango de proximidad establecido para los puntos de referencia. En este caso, al acercarse lo suficiente a un punto específico, la aplicación detectará la ubicación del usuario y activará el contenido multimedia o la información correspondiente a ese punto en particular. Por ejemplo, un video como en este ejemplo.</p>

    <div class="contenedor-imagen-informacion-cuerpo">
    <img  src={Imagen3} alt="Imagen explicando geowex" />
    </div>


    <h2 className='h2-informacion'>CARACTERISTICAS:</h2>
    <p className='p-informacion'>La aplicación ofrece una amplia gama de opciones puntos de referencia o interés (POI), que incluyen audios, videos, fotos, texto descriptivo, encuestas interactivas, documentos en formato PDF y experiencias de realidad aumentada. Estos checkpoints permiten a los usuarios añadir una gran diversidad de contenido a sus mapas, creando experiencias interactivas y enriquecedoras para los usuarios.
    </p>

    <div class="contenedor-imagen-informacion-cuerpo">
    <img  src={Imagen2} alt="Caracteristicas" />
    </div>

    <p className='p-informacion'>Los POI  se activan por proximidad, mientras se realiza el recorrido. 
    </p>

    <p className='p-informacion'>
    </p>

    <p className='p-informacion'>
    </p>

    </main>
        </div>

     
      );
}

export default Informacion;