import React from 'react';
import HeaderMain from '../reutilizable/HeaderMain';
import Celular from '../../imagenes/celular.png';
import Computadora from '../../imagenes/computadora.png';
import Foco from '../../imagenes/foco.png';
import Educacion from '../../imagenes/educacion.jpg';
import Agronomia from '../../imagenes/agronomia.jpg';
import Turismo from '../../imagenes/turismo.jpg';
import Proyectos from '../../imagenes/proyectos.jpg';
import Imagen1 from '../../imagenes/informacion1.png';
import '../estilos/ProyectosEjemplo.css';
function ProyectosEjemplos() {
    

  return (

    <div>
    <HeaderMain />

<main className='main-informacion'>
      
<h1 className='h1-informacion'>Ejemplos de proyectos generados</h1>

<section className='section-informacion'>

<div class="iconos-informacion">

<div class="icono-informacion">
       <img class="contenedor-imagen-informacion" src={Educacion} alt="Logo de la pagina" />
    <p class="text-informacion">Nuestras obras las terminamos en menos de un año</p>
    <h3 className='h3-informacion'>Educación</h3>
</div>
  
<div class="icono-informacion">
     <img class="contenedor-imagen-informacion" src={Agronomia} alt="Logo de la pagina" />
    <p class="text-informacion">Nuestra oficina se encuentra en AV. LIBERTADOR 345</p>
    <h3 className='h3-informacion'>Agronomía</h3>
</div>

<div class="icono-informacion">
    <img class="contenedor-imagen-informacion" src={Turismo} alt="Logo de la pagina" />
    <p class="text-informacion">Aceptamos todos los medios de pagos</p>
    <h3 className='h3-informacion'>Turismo</h3>
</div>

<div class="icono-informacion">
      <img class="contenedor-imagen-informacion" src={Proyectos} alt="Logo de la pagina" />
    <p class="text-informacion">Contamos con 50 empleados de todos los rubros de construccion</p>
    <h3 className='h3-informacion'>Otros</h3>
</div>

</div>


</section>


<h2 className='h2-informacion'>EDUCACIÓN:</h2>
<p className='p-informacion'>Los docentes pueden utilizar nuestra aplicación en el aula para crear actividades educativas interactivas basadas en ubicaciones geográficas. Por ejemplo, pueden diseñar una búsqueda del tesoro en la que los estudiantes deben encontrar puntos de interés histórico o geográfico en un área específica y responder preguntas relacionadas con cada ubicación. También pueden crear recorridos virtuales para complementar lecciones sobre geografía, historia o ciencias naturales. 
</p>

<h2 className='h2-informacion'>EXPLORADORES URBANOS:</h2>
<p className='p-informacion'> Los exploradores urbanos pueden utilizar nuestra aplicación para descubrir y compartir lugares interesantes en entornos urbanos. Pueden crear mapas temáticos que destaquen arte callejero, puntos de encuentro locales, tiendas únicas, cafeterías acogedoras y otros puntos de interés cultural. Además, pueden organizar eventos y encuentros basados en ubicaciones específicas, como recorridos artísticos o excursiones gastronómicas por la ciudad.
</p>

<h2 className='h2-informacion'>TURISTAS:</h2>
<p className='p-informacion'>Los turistas pueden utilizar nuestra aplicación para crear mapas personalizados de sus destinos de viaje. Pueden marcar puntos de interés como monumentos, restaurantes, hoteles y atracciones turísticas, y agregar descripciones, fotos y reseñas para cada ubicación. Además, pueden trazar rutas para sus itinerarios diarios y compartir sus mapas con otros viajeros para obtener recomendaciones y consejos locales.
</p>

<h2 className='h2-informacion'>AGRONOMÍA:</h2>
<p className='p-informacion'>Los profesionales de la agronomía pueden utilizar nuestra aplicación para mapear campos agrícolas, identificar áreas de cultivo y monitorear el crecimiento de cultivos a lo largo del tiempo. Pueden agregar checkpoints con información detallada sobre los tipos de cultivos, las condiciones del suelo y los métodos de cultivo utilizados en cada área. Esto les permite realizar un seguimiento preciso de la producción agrícola y tomar decisiones informadas sobre la gestión de los cultivos.
</p>

</main>
    </div>
  );
}

export default  ProyectosEjemplos;